import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "snack--bar__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showSnackbar)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["snack--bar", _ctx.snackBarClass])
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fa snack--bar__icon", {
                'fa-times': _ctx.snackBarClass == 'snack--bar__error',
                'fa-check': _ctx.snackBarClass == 'snack--bar__success'
            }])
        }, null, 2),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1),
        _createElementVNode("i", {
          class: "fa fa-times snack--bar__clear",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.showSnackbar = false;})
        })
      ], 2))
    : _createCommentVNode("", true)
}