
import { Vue } from 'vue-class-component';
import { useStore } from 'vuex';

export default class Snackbar extends Vue {

    public store = useStore();
    public message:string = '';
    public snackBarClass: string = '';
    public showSnackbar: boolean = false;

    mounted() {
        this.store.subscribe((mutation, state) => {
            if(mutation.type == 'onShowSnackbar') {
                this.message = state['ContextModule'].message;
                this.snackBarClass = state['ContextModule'].snackBarClass;
                this.showSnackbar = true;

                setTimeout(() => {
                    this.showSnackbar = false;
                }, 10000)
            }
        });
        
    }
}
